function impersonate() {
    return frappe.call({
        method: 'portland_jewellers_custom.api.impersonate.impersonate',
        args: {
            customer: ($('#selected-customer').text())
        },
        callback: function (r) {
            if (r.message.status === 'Success') {
                frappe.show_alert({ message: r.message.message, indicator: 'green' });
                location.reload(true);
            } else {
                frappe.throw(r.message.message);
            }
        }
    })
}

$("#btn-impersonate-customer").on("click", function () {
    $(this).prop("disabled", true);
    impersonate(this);
    setTimeout(function() {
        $("#btn-impersonate-customer").prop("disabled", false);
    }, 5000);
});